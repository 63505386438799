import routerHelper from '@/router/helper/index.js';

import { ViewScreens } from '@/constants/views/viewScreens';

const createInitialState = () => ({
  type: new Error('view type is required'),
  availableGroups: null,
  isLockedStatuses: false,
  listingType: null,
  token: '',
  canCreateCommentsFromSharing: false,
  params: {
    defaultCardType: null,
    teamId: null,
    datasourceId: null,
    viewId: null,
  },
  datasource: null,
  accessMode: null,
  name: '',
  createdById: null,
  cardSettings: null,
  subtaskDisplay: null,
  taskElements: null,
  hasHeader: null,
  isLockedColumns: null,
  icon: null,
  avatar: null,
  emoji: null,
});

const state = createInitialState();

const getters = {
  isRoomView(state) {
    return state.type === ViewScreens.Room;
  },
};

const mutations = {
  setParams(state, params) {
    state.params = params;
  },
  setType(state, type) {
    state.type = type;
  },
  setToken(state, token) {
    state.token = token;
  },
  setCanCreateCommentsFromSharing(state, canCreateCommentsFromSharing) {
    state.canCreateCommentsFromSharing = canCreateCommentsFromSharing;
  },
  setAvailableGroups(state, availableGroups) {
    state.availableGroups = availableGroups;
  },
  setIsLockedStatuses(state, isLockedStatuses) {
    state.isLockedStatuses = isLockedStatuses;
  },
  setListingType(state, listingType) {
    state.listingType = listingType;
  },
  setDefaultCardSettings(state) {
    state.cardSettings = {
      status: true,
      executors: true,
      slug: true,
      date: true,
      repeat: true,
      cardType: true,
      interactiveButtons: true,
      informationalButtons: true,
      responsible: true,
      tags: true,
      viewers: true,
      cover: true,
      cost: false,
    };
  },
  setCardSettings(state, cardSettings) {
    state.cardSettings = cardSettings;
  },
  setDefaultType(state, defaultType) {
    state.params.defaultCardType = defaultType;
  },
  setDatasource(state, datasource) {
    state.datasource = datasource;
    state.params.datasourceId = datasource?.id;
  },
  setViewName(state, name) {
    state.name = name;
  },
  setAccessMode(state, mode) {
    state.accessMode = mode;
  },
  setCreatedById(state, createdById) {
    state.createdById = createdById;
  },
  setSubtaskDisplay(state, subtaskDisplay) {
    state.subtaskDisplay = subtaskDisplay;
    routerHelper.appendGetParams({ subtasksCode: subtaskDisplay }, { isSaveHistory: false });
  },
  setTaskElements(state, taskElements) {
    state.taskElements = taskElements;
  },
  setHasHeader(state, hasHeader) {
    state.hasHeader = hasHeader;
  },
  setIcon(state, icon) {
    state.icon = icon;
  },
  setEmoji(state, emoji) {
    state.emoji = emoji;
  },
  setAvatar(state, avatar) {
    state.avatar = avatar;
  },
  setIsLockedColumns(state, isLockedColumns) {
    state.isLockedColumns = isLockedColumns;
  },
  resetAvailableGroups(state) {
    state.availableGroups = null;
  },
  reset(state) {
    Object.assign(state, createInitialState());
  },
};

const actions = {
  RESET({ commit }) {
    commit('reset');
  },
  initializeViewStore({ state, commit }, { view, teamId }) {
    if (view.id !== state.params.viewId) {
      commit('setParams', {
        teamId,
        datasourceId: view.datasource?.id,
        viewId: view.id,
        defaultCardType: view.defaultCardType,
      });
    }
    commit('setDatasource', view.datasource);
    commit('setViewName', view.name);
    commit('setAccessMode', view.access);
    commit('setIsLockedStatuses', view.isLockedStatuses);
    commit('setType', ViewScreens.Room);
    commit('setListingType', view.type);
    commit('setCardSettings', view.cardsSettings);
    commit('setToken', view.publicToken);
    commit('setCreatedById', view.createdById);
    commit('setSubtaskDisplay', view.subtaskDisplay);
    commit('setTaskElements', view.taskElements);
    commit('setCanCreateCommentsFromSharing', view.canCreateCommentsFromSharing);
    commit('setHasHeader', view.hasHeader);
    commit('setIsLockedColumns', view.isLockedColumns);
    commit('setIcon', view.icon);
    commit('setEmoji', view.emoji);
    commit('setAvatar', view.avatar);
  },
};

export default {
  namespaced: true,

  state,
  getters,
  mutations,
  actions,
};
