export default {
  openInNewTab: 'Open in new tab',
  link: 'Link',
  edit: 'Edit',
  independentTask: 'Independent card',
  assignToMe: 'Assign to me',
  duplicate: 'Duplicate',
  archive: 'Archive',
  delete: 'Delete',
};
