import type { BlockedByAnother, BlockedByMe } from '@/api/tasks/blocked';
import type { TaskTypeDetail } from '@/models/CardTypes';
import type { Currency } from '@/models/Currency';
import type { Datasource } from '@/models/Datasource';
import type { Status } from '@/models/Status';
import type { Subtask } from '@/models/tasks/Subtask';
import type { TaskExecutor } from '@/models/tasks/TaskExecutor';
import type { TeamTag } from '@/models/teams/TeamTag';

import type { File } from '@/components/tasks/details/properties/files/shared/sharedFilesProperties';
import type { DurationCodes } from '@/components/tasks/shared/vueSelectInterval/constants';

import type Translation from '@/utils/translation';

import { ADMIN, MANAGER, OWNER, USER } from '@/constants/permissions/listRoles';

import type { Nullable } from '@/types/utility';

export interface User {
  avatar: string;
  first_name: string;
  full_name: string;
  id: number;
  last_name: Nullable<string>;
  phone_number: string;
  username: string;
  online: boolean;
  tracking: Nullable<number>;
  color: string;
  last_team: number;
}

const roles = [OWNER, ADMIN, MANAGER, USER];

export type TeamMember = {
  id: number;
  user: User;
  accepted: boolean;
  is_blocked: boolean;
  salary: string;
  role: (typeof roles)[number];
};

export interface Stage {
  id: number;
  name: string;
  hours: number;
  date_from: string;
  date_until: string;
  is_break: boolean;
  position: number;
  order_by: string;
  color: string;
}

export interface TaskResponsible {
  id: number;
  is_confirmed_closing: boolean;
  responsible: User;
}

export interface TaskViewer {
  count: number;
  user: User;
}

export interface CoverSettings {
  black: boolean;
  full_image: boolean;
}

type BaseTask = {
  id: number;
  archived: boolean;
  completed: boolean;
  name: string;
  status: Nullable<Status>;
  children: Array<number>;
  slug: string;
  priority: string;
  deadline: Nullable<string>;
  deadline_time: Nullable<string>;
  date_start: Nullable<string>;
  date_start_time: Nullable<string>;
  executors: Array<TaskExecutor>;
  responsibles: Array<TaskResponsible>;
  task_labels: Array<TeamTag>;
  blocked_by_another: number | Array<BlockedByAnother>;
  blocked_by_yourself: number | Array<BlockedByMe>;
  blocked_by_another_count: number;
  blocked_by_yourself_count: number;
  files_count: number;
  comments_count: number;
  viewers: Array<TaskViewer>;
  datasource: number;
  cover: string | null;
  cover_settings: CoverSettings;
  datasource_detail: Datasource;
  stage: Nullable<Stage>;
  cost: Nullable<string>;
  currency_value: Currency;
  planned_time_minutes: number;
  planned_time_type: DurationCodes;
};

export interface CreatedFromSchedule {
  id: number;
  run_time: Date;
}

export type Parent = {
  id: number;
  completed: boolean;
  name: string;
  slug: string;
};

type CustomFieldValue = { id: number; value: string; color: string };
type TaskCustomFieldValue = {
  extra: Nullable<string>;
  file: Nullable<string>;
  file_extension: Nullable<string>;
  filename: Nullable<string>;
  id: number;
  option: Nullable<CustomFieldValue>;
  size: Nullable<string>;
  task: number;
  user: Nullable<User>;
  value: Nullable<string>;
};

type TaskCustomField = {
  custom_field_values: [TaskCustomFieldValue];
  extras: Nullable<string[]>;
  id: string;
  multiple_choice: boolean;
  name: string;
  type: string;
  values: [CustomFieldValue];
};

export type Task = BaseTask & {
  subtasks_count: number;
  check_lists_count: number;
  completed_subtasks: number;
  resolved_check_lists_count: number;
  created_from_schedule: Nullable<CreatedFromSchedule>;
  date_archived: string;
  datasource_detail: Datasource;
  archived_by: Nullable<User>;
  cover_settings: string;
  can_change_status_to: Array<number>;
  parent?: Parent;
  children?: Subtask[];
  type?: TaskTypeDetail;
  tracked_seconds: number;
  custom_fields: Array<TaskCustomField>;

  view_calendar_position: number;
  view_priority_position: number;
  view_stage_position: number;
  view_status_position: number;

  my_calendar_position: number;
  my_priority_position: number;
  my_stage_position: number;
  my_status_position: number;

  calendar_position: number;
  priority_position: number;
  stage_position: number;
  status_position: number;
};
export type TaskDetails = BaseTask & {
  can_create_comments_from_sharing: boolean;
  parent: Nullable<Subtask>;
  public_token: Nullable<string>;
  type: TaskTypeDetail;
  wasted_time: number;
  default_task_type: Nullable<number>;
  can_create_shared_comments?: boolean;
  custom_fields: Array<TaskCustomField>;
};

export interface PaginationResponse<T = unknown> {
  count?: number;
  num_pages: number;
  results: T;
}

export interface ListItem {
  title?: string | Translation;
  code?: string | number;
  id?: number;
}

export enum Locales {
  RU = 'ru',
  EN = 'en',
}

export interface ActionsViewer {
  next?: (file: File) => File;
  prev?: (file: File) => File;
  set?: (card: { id: string }) => File;
  gallery?: (file: File) => File[];
  delete: (file: File) => void;
  download?: (card: { url: string; name: string }) => void;
  isMultiple?: boolean;
}
