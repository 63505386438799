export enum DefaultFields {
  EXECUTORS = 'executor',
  RESPONSIBLE = 'responsible',
  STATUS = 'status',
  DUE_DATE = 'deadline',
  PRIORITY = 'priority',
  DURATION = 'estimate',
  STAGE = 'stage',
  LINKS = 'connections',
  TAGS = 'labels',
  COST = 'cost',
}
