import session from '@/api/session';
import { taskTemplateChecklist } from '@/models/tasks/TaskTemplate';

import ZodHelper from '@/helpers/ZodHelper';

type BaseTemplateBody = {
  text: string;
  task_template: number;
  resolved?: boolean;
  position?: number;
};
type CreateTemplateChecklistParams = {
  datasourceId: number;
  templateId: number;
  requestBody: BaseTemplateBody;
};
type EditTemplateChecklistParams = Omit<CreateTemplateChecklistParams, 'requestBody'> & {
  checklistId: number;
  requestBody: BaseTemplateBody;
};
type DeleteTemplateChecklistParams = Omit<EditTemplateChecklistParams, 'requestBody'>;

type GetTemplateChecklistParams = Omit<CreateTemplateChecklistParams, 'requestBody'>;

export const apiTaskTemplateChecklist = {
  getTemplateChecklists({ datasourceId, templateId }: GetTemplateChecklistParams) {
    return ZodHelper.request(
      () => session.get(`tasks/task-templates/${datasourceId}/check-list/${templateId}/list/`),
      taskTemplateChecklist.array(),
    );
  },

  createTemplateChecklist({ datasourceId, templateId, requestBody }: CreateTemplateChecklistParams) {
    return ZodHelper.request(
      () => session.post(`tasks/task-templates/${datasourceId}/check-list/${templateId}/create/`, requestBody),
      taskTemplateChecklist,
    );
  },
  editTemplateChecklist({ datasourceId, templateId, checklistId, requestBody }: EditTemplateChecklistParams) {
    return ZodHelper.request(
      () => session.patch(`tasks/task-templates/${datasourceId}/check-list/${templateId}/${checklistId}/`, requestBody),
      taskTemplateChecklist,
    );
  },
  deleteTemplateChecklist({ datasourceId, templateId, checklistId }: DeleteTemplateChecklistParams) {
    return session.delete(`tasks/task-templates/${datasourceId}/check-list/${templateId}/${checklistId}/`);
  },
};
