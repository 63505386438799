import CRUD from '@/api/tasks/CRUD';
import apiGanttTasks from '@/api/tasks/apiGanttTasks';
import archive from '@/api/tasks/archive';
import blocked from '@/api/tasks/blocked';
import checklist from '@/api/tasks/checklist';
import commentFiles from '@/api/tasks/commentFiles';
import comments from '@/api/tasks/comments';
import { customFields } from '@/api/tasks/customFields';
import executors from '@/api/tasks/executors';
import files from '@/api/tasks/files';
import { filters } from '@/api/tasks/filters';
import repeat from '@/api/tasks/repeat';
import responsible from '@/api/tasks/responsible';
import screenshots from '@/api/tasks/screenshots';
import share from '@/api/tasks/share';
import subtasks from '@/api/tasks/subtasks';
import tags from '@/api/tasks/tags';
import { apiTaskTemplates } from '@/api/tasks/templates';
import { customFieldsTemplates } from '@/api/tasks/templates/customFieldsTemplates';
import subTemplates from '@/api/tasks/templates/subTemplates';
import trackedTime from '@/api/tasks/trackedTime';
import tracker from '@/api/tasks/tracker';
import upload from '@/api/tasks/upload';
import { apiTaskUsers } from '@/api/tasks/users';
import viewers from '@/api/tasks/viewers';

export default {
  ...CRUD,
  ...comments,
  ...commentFiles,
  ...files,
  ...checklist,
  ...screenshots,
  ...tags,
  ...executors,
  ...responsible,
  ...repeat,
  ...blocked,
  ...tracker,
  ...share,
  ...trackedTime,
  ...viewers,
  ...archive,
  ...subtasks,
  ...customFields,
  ...filters,
  ...upload,
  ...apiTaskUsers,
  ...apiTaskTemplates,
  ...apiGanttTasks,
  ...subTemplates,
  ...customFieldsTemplates,
};
