import session from '@/api/session';
import { subTemplates } from '@/models/tasks/SubTemplates';

import ZodHelper from '@/helpers/ZodHelper';

type GetSubTemplates = {
  templateId: number;
  datasourceId: number;
};

export default {
  getSubTemplates({ templateId, datasourceId }: GetSubTemplates) {
    return ZodHelper.request(
      () => session.get(`tasks/task-templates/${datasourceId}/${templateId}/subtemplates/`),
      subTemplates.array(),
    );
  },
};
