import session from '@/api/session';
import { apiAllInOne } from '@/api/teams/allInOne';
import cardTypes from '@/api/teams/cardTypes';
import customFields from '@/api/teams/customFields';
import integrations from '@/api/teams/integrations';
import mailIntegrations from '@/api/teams/mailIntegrations';
import apiStatuses from '@/api/teams/statuses';
import apiTeamTags from '@/api/teams/tags';

export default {
  createTeam(requestBody) {
    return session.post('teams/team/create/', requestBody);
  },
  getTeams() {
    return session.get('teams/team/list/');
  },
  getTeamDetails(teamId) {
    return session.get(`teams/team/detail/${teamId}/`);
  },
  updateTeam({ teamId, data }) {
    return session.patch(`teams/team/update/${teamId}/`, data);
  },
  setTeamAvatar({ teamId, data }) {
    return session.patch(`teams/team/avatar/${teamId}/add/`, data);
  },
  deleteTeam(teamId) {
    return session.delete(`/teams/team/delete/${teamId}/`);
  },
  sendDeleteTeam(requestBody) {
    return session.post('/teams/team/team-delete-response/', requestBody);
  },
  getTeamMembersPaginated({ teamId, getParams: params }) {
    return session.get(`/teams/members/paginated-list/${teamId}/`, { params });
  },
  getTeamMembers(teamId, params) {
    return session.get(`/teams/members/list/${teamId}/`, { params });
  },
  getUsersForInvite({ teamId, getParams: params }) {
    return session.get(`teams/members/list/to-invite/${teamId}/`, { params });
  },
  getTeamMemberProfile({ teamId, memberId }) {
    return session.get(`teams/members/${teamId}/user/${memberId}/`);
  },
  updateTeamMember({ teamId, memberId, requestBody }) {
    return session.patch(`/teams/members/${teamId}/user/${memberId}/update/`, requestBody);
  },
  setTeamMemberProjects({ teamId, memberId, requestBody }) {
    return session.patch(`/teams/members/${teamId}/user/${memberId}/projects/`, requestBody);
  },

  deleteTeamMember({ teamMemberId, teamId }) {
    return session.delete(`/teams/members/${teamId}/delete/${teamMemberId}/`);
  },

  invitePerson(data) {
    return session.post('teams/invite-member/add/', data);
  },
  checkInvitationToken(token) {
    return session.post('teams/invite-member/accept/', { token });
  },
  acceptInvitation(requestBody) {
    return session.post('teams/invite-member/accept/', requestBody);
  },
  declineInvitation(requestBody) {
    return session.post('teams/invite-member/decline/', requestBody);
  },
  generateInviteToken(requestBody) {
    return session.post('teams/invite-member/invite-link/', requestBody);
  },
  ...cardTypes,
  ...customFields,
  ...apiStatuses,
  ...apiTeamTags,
  ...apiAllInOne,
  ...integrations,
  ...mailIntegrations,
};
