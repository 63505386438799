import { svgIcons } from '@/constants/icons';

export const icons = [
  {
    name: svgIcons.BOARD,
  },
  {
    name: svgIcons.LIST,
  },
  {
    name: svgIcons.MATRIX,
    fill: false,
  },
  {
    name: svgIcons.GANTT,
    fill: false,
  },
  {
    name: svgIcons.TIMELINE,
    fill: false,
  },
  {
    name: svgIcons.CALENDAR,
  },
  {
    name: svgIcons.PAGE,
    fill: false,
  },
  {
    name: svgIcons.USERS,
    fill: false,
  },
  {
    name: svgIcons.HELP,
  },
  {
    name: svgIcons.AT,
  },
  {
    name: svgIcons.CLOCK,
  },
  {
    name: svgIcons.COPY,
  },
  {
    name: svgIcons.DOWNLOAD,
  },
  {
    name: svgIcons.FIRE,
  },
  {
    name: svgIcons.FOLDER,
  },
  {
    name: svgIcons.FOLDER_OPEN,
    fill: false,
  },
  {
    name: svgIcons.PHONE,
    fill: false,
  },
  {
    name: svgIcons.TASK,
    fill: false,
  },
  {
    name: svgIcons.STAR,
    fill: false,
  },
  {
    name: svgIcons.LANGUAGE,
    fill: false,
  },
  {
    name: svgIcons.ROCKET,
    fill: false,
  },
  {
    name: svgIcons.PRICE,
  },
  {
    name: svgIcons.POUND,
    fill: false,
  },
  {
    name: svgIcons.PICTURE,
    fill: false,
  },
  {
    name: svgIcons.ATTACHMENT,
    fill: false,
  },
  {
    name: svgIcons.SAVE,
    fill: false,
  },
];
