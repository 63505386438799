export default {
  steps: {
    create: 'Creation',
    auth: 'Authorization',
    connect: 'Connection',
    start: 'Start work',
  },
  banks: {
    modulebank: {
      gen: 'ModulBank',
      loc: 'ModulBank',
    },
    tinkoff: {
      gen: 'T-Bank',
      loc: 'T-Bank',
    },
  },
  createTitle: 'Why do we need integration with the bank',
  description:
    'Thanks to the integration, you connect directly to the bank so that financial transactions are automatically uploaded to the service.',
  connection:
    'After connecting to {bankName}, you will not need to enter accounts or transactions into the service manually or through export/import. They arrive automatically at intervals of up to 30 minutes.',
  authTitle: 'How to enable integration',
  auth: 'Log in to the {bankName} website',
  confirm: 'Confirm permission to download statement',
  choose: 'Select the required accounts and synchronize data',
  readDoc: 'Read the full documentation',
  authButton: 'Authorization in the {bankName}',
  title: 'Title',
  enterTitle: 'Enter integration title',
  sync: 'Synchronize',
  startWork: 'StartWork',
  inn: 'INN: {inn}',
  balance: 'Balance ',
  createAuto: 'Create automatically',
  checkStatus: 'Check authorization status',
  allOrganizations: 'All organizations',
  authorizationSuccess: 'Authorization completed successfully',
  connectionSuccess: 'Accounts connected successfully',
  deleteIntegration: 'Delete integration',
  noData: 'No accounts were found for your request. Check if accounts exist in {bankName} and then return to this step',
};
