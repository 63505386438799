export default {
  title: 'Choose Deletion Option',

  types: {
    board: { nominative: 'Board', accusative: 'board', gender: 'feminine' },
    list: { nominative: 'List', accusative: 'list', gender: 'masculine' },
    matrix: { nominative: 'Matrix', accusative: 'matrix', gender: 'feminine' },
    calendar: { nominative: 'Calendar', accusative: 'calendar', gender: 'masculine' },
    timeline: { nominative: 'Timeline', accusative: 'timeline', gender: 'masculine' },
    gantt: {
      nominative: 'Gantt Chart',
      accusative: 'gantt chart',
      genitive: "gantt chart's",
      gender: 'feminine',
    },
    files: { nominative: 'Files', accusative: 'files', gender: 'plural' },
    folder: { nominative: 'Folder', accusative: 'folder', gender: 'feminine' },
    page: { nominative: 'Page', accusative: 'page', gender: 'feminine' },
  },
  genders: {
    feminine: { pronoun: 'its', verb: 'will be deleted' },
    masculine: { pronoun: 'its', verb: 'will be deleted' },
    plural: { pronoun: 'its', verb: 'will be deleted' },
  },

  titles: {
    yourself: 'Delete only {type}',
    part: 'Delete {type} and attachments',
    all: 'Delete {type} and attachments, and move all data to archive',
  },

  confirm: 'Enter name',

  description: {
    yourself:
      '{type} {verb}, and {pronoun} contents, including nested folders, boards, etc., will move to the top level.',
    part: '{type} and {pronoun} contents, including nested folders, boards, etc., will be deleted, and cards will be stored in the database.',
    all: '{type} and {pronoun} contents, including nested folders, boards, etc., will be deleted, and cards will be sent to the Archive.',
  },

  delete: 'Delete',
  cancel: 'Cancel',
};
