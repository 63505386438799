import session from '@/api/session';
import getRequestUrl from '@/api/utils/getRequestUrl';

export default {
  getCommentFiles({ taskId, commentId }) {
    return session.get(`/tasks/comments/files/${taskId}/comment_file/${commentId}/list/`);
  },
  createCommentFile({ taskId, fd, config }) {
    const sharedUrl = `/shared/tasks/comment/${taskId}/comment_file/create/`;
    const defaultUrl = `/tasks/comments/files/${taskId}/comment_file/create/`;

    return session.post(getRequestUrl(defaultUrl, sharedUrl), fd, config);
  },

  createCommentTemplateFile({ datasourceId, templateId, fd, config }) {
    return session.post(`/tasks/task-templates/${datasourceId}/comments/${templateId}/file/create/`, fd, config);
  },

  deleteCommentFile({ taskId, commentId, fileId }) {
    return session.delete(`/tasks/comments/files/${taskId}/comment_file/${commentId}/delete/${fileId}/`);
  },
  preSignCommentFileForS3({ id: taskId, fileData, signature }) {
    return session.post(`/tasks/comments/files/${taskId}/comment_file/s3/pre-sign/`, {
      ...fileData,
      signature,
    });
  },
};
