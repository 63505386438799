import type { AxiosRequestConfig } from 'axios';

import session from '@/api/session';
import { taskCustomField, taskCustomFieldValue } from '@/models/tasks/TaskCustomField';

import ZodHelper from '@/helpers/ZodHelper';

type EditTaskCustomField = {
  taskId: number;
  valueId: number;
  requestBody:
    | {
        field: string;
        task?: number;
        extra?: string;
        file?: string;
        user?: number;
        value?: string;
        option?: number;
        task_connection?: number;
      }
    | FormData;
  config?: AxiosRequestConfig;
};

type CreateTaskCustomField = Omit<EditTaskCustomField, 'valueId'>;

type DeleteTaskCustomField = Omit<EditTaskCustomField, 'requestBody'>;

type GetCustomFilesArchive = Omit<EditTaskCustomField, 'requestBody' | 'valueId' | 'config'> & {
  fieldId: string;
};

export const customFields = {
  editTaskCustomField({ taskId, valueId, requestBody }: EditTaskCustomField) {
    return session.patch(`tasks/customfields/${taskId}/update/${valueId}/`, requestBody);
  },
  createCustomFieldValue({ taskId, requestBody, config }: CreateTaskCustomField) {
    return ZodHelper.request(
      () => session.post(`tasks/customfields/${taskId}/create/`, requestBody, config),
      taskCustomFieldValue,
    );
  },
  deleteCustomFieldValue({ taskId, valueId }: DeleteTaskCustomField) {
    return session.delete(`tasks/customfields/${taskId}/delete/${valueId}/`);
  },
  getTaskCustomFields({ taskId }: { taskId: number }) {
    return ZodHelper.request(() => session.get(`tasks/customfields/${taskId}/list/`), taskCustomField.array());
  },
  getCustomFieldsByView({ viewId, taskId }: { viewId: number; taskId: number }) {
    return ZodHelper.request(
      () => session.get(`tasks/customfields/${viewId}/${taskId}/list/`),
      taskCustomField.array(),
    );
  },
  getCustomFieldFilesArchive({ taskId, fieldId }: GetCustomFilesArchive) {
    return session.get(`tasks/customfields/${taskId}/archive/${fieldId}/`, { responseType: 'blob' });
  },
};
