import type { AxiosRequestConfig } from 'axios';

import session from '@/api/session';
import { taskCustomFieldValue } from '@/models/tasks/TaskCustomField';

import ZodHelper from '@/helpers/ZodHelper';

export type FieldValue = {
  field: string;
  task_template?: number;
  extra?: string;
  file?: string;
  user?: number;
  value?: string;
  option?: number;
  task_connection?: number;
};

type EditTemplateCustomField = {
  datasourceId: number;
  templateId: number;
  valueId: number;
  requestBody: FieldValue | FormData;
  config?: AxiosRequestConfig;
};

type CreateTemplateCustomField = Omit<EditTemplateCustomField, 'valueId'>;

type DeleteTemplateCustomField = Omit<EditTemplateCustomField, 'requestBody'>;

type GetTemplateCustomFilesArchive = Omit<EditTemplateCustomField, 'requestBody' | 'config' | 'valueId'> & {
  fieldId: string;
};

export const customFieldsTemplates = {
  editTemplateCustomField({ datasourceId, templateId, valueId, requestBody }: EditTemplateCustomField) {
    return session.patch(
      `tasks/task-templates/${datasourceId}/customfields/${templateId}/update/${valueId}/`,
      requestBody,
    );
  },

  createTemplateCustomFieldValue({ datasourceId, templateId, requestBody, config }: CreateTemplateCustomField) {
    return ZodHelper.request(
      () =>
        session.post(`tasks/task-templates/${datasourceId}/customfields/${templateId}/create/`, requestBody, config),
      taskCustomFieldValue,
    );
  },

  deleteTemplateCustomFieldValue({ datasourceId, templateId, valueId }: DeleteTemplateCustomField) {
    return session.delete(`tasks/task-templates/${datasourceId}/customfields/${templateId}/delete/${valueId}/`);
  },

  getCustomTemplateFieldFilesArchive({ datasourceId, templateId, fieldId }: GetTemplateCustomFilesArchive) {
    return session.get(`tasks/task-templates/${datasourceId}/customfields/${templateId}/archive/${fieldId}/`, {
      responseType: 'blob',
    });
  },
};
