import { z } from 'zod';

import { taskTypeDetail } from '@/models/CardTypes';
import { datasource } from '@/models/Datasource';
import { status } from '@/models/Status';
import { AccountTypeCodes, Currency } from '@/models/finances/FinancesAccounts';

export enum IntegrationCodes {
  MAIL = 'mail',
  MODULBANK = 'modulebank',
  BITBUCKET = 'bitbucket',
  TINKOFF = 'tinkoff',
  GITHUB = 'github',
  TOCHKA_BANK = 'tochkaBank',
  GITLAB = 'gitlab',
  SENTRY = 'sentry',
  LAMBDATEST = 'lambdatest',
  QASE = 'qase',
  SLACK = 'slack',
  TEAMS = 'teams',
}

export const teamIntegration = z.object({
  first_sync: z.boolean(),
  got_token: z.boolean(),
  id: z.number(),
  integration_id: z.string().nullable(),
  integration_name: z.string(),
  integration_type: z.nativeEnum(IntegrationCodes),
  is_active: z.boolean(),
});

export const bankDetails = z.object({
  int_type: z.string().optional(),
  accounts: z
    .array(
      z.object({
        bank_account_id: z.string(),
        id: z.number(),
        name: z.string(),
        balance: z.number(),
        currency: z.nativeEnum(Currency),
        account_type: z.nativeEnum(AccountTypeCodes),
        company_id: z.string().nullable(),
      }),
    )
    .nullish(),
});

export const mailDetails = z
  .object({
    id: z.number(),
    host: z.string(),
    host_password: z.string(),
    host_port: z.number(),
    host_username: z.string(),
    protocol: z.string(),
    datasource: datasource.pick({
      id: true,
      name: true,
    }),
    status: status.nullable(),
    task_type: taskTypeDetail.omit({ fields: true }).nullable(),
    use_ssl: z.boolean(),
  })
  .transform(({ host_password, host_username, host_port, task_type, use_ssl, ...rest }) => ({
    ...rest,
    ssl: use_ssl,
    password: host_password,
    port: host_port,
    email: host_username,
    cardType: task_type,
  }));

export const mailIntegration = mailDetails
  .innerType()
  .extend({
    integrationName: z.string(),
    isEdit: z.boolean(),
    datasourceId: z.number(),
    integrationId: z.string(),
  })
  .transform(({ host_password, host_username, host_port, task_type, use_ssl, ...rest }) => ({
    ...rest,
    ssl: use_ssl,
    password: host_password,
    port: host_port,
    email: host_username,
    cardType: task_type,
  }));

export const integrationDetail = teamIntegration.extend({
  data: bankDetails,
});

export const mailIntegrationItem = teamIntegration
  .omit({ integration_id: true })
  .extend({ integration_id: z.string() });

export type IntegrationDetail = z.infer<typeof integrationDetail>;
export type TeamIntegration = z.infer<typeof teamIntegration>;
export type MailDetails = z.infer<typeof mailDetails>;
export type MailIntegration = z.infer<typeof mailIntegration>;
export type MailIntegrationItem = z.infer<typeof mailIntegrationItem>;
