import TextHelper from '@/helpers/TextHelper';

import {
  canUseFeed,
  canManualAddTime,
  canUseCustomFields,
  canUseTaskHistory,
  canUseTaskCovers,
  canUseCardType,
  canChangeSharingSettings,
  canUseFinances,
  canUseGantt,
  canManageTemplates,
  canRepeatTask,
  canUseCard,
} from '@/constants/permissions/listBillingPermissions';

const BILLING_PERMISSIONS_LS_KEY = 'billingPermissions';

const createInitialState = () => ({
  permissions: TextHelper.safeParseJSON(localStorage.getItem(BILLING_PERMISSIONS_LS_KEY)) || [],
});

const state = createInitialState();

const getters = {
  checkPermission(state) {
    return (permission) => state.permissions.includes(permission);
  },

  [canUseFeed](_skip, { checkPermission }) {
    return checkPermission(canUseFeed);
  },
  [canManualAddTime](_skip, { checkPermission }) {
    return checkPermission(canManualAddTime);
  },
  [canUseCustomFields](_skip, { checkPermission }) {
    return checkPermission(canUseCustomFields);
  },
  [canUseTaskHistory](_skip, { checkPermission }) {
    return checkPermission(canUseTaskHistory);
  },
  [canUseTaskCovers](_skip, { checkPermission }) {
    return checkPermission(canUseTaskCovers);
  },
  [canUseCardType](_skip, { checkPermission }) {
    return checkPermission(canUseCardType);
  },
  [canChangeSharingSettings](_skip, { checkPermission }) {
    return checkPermission(canChangeSharingSettings);
  },
  [canUseFinances](_skip, { checkPermission }) {
    return checkPermission(canUseFinances);
  },
  [canUseGantt](_skip, { checkPermission }) {
    return checkPermission(canUseGantt);
  },
  [canManageTemplates](_skip, { checkPermission }) {
    return checkPermission(canManageTemplates);
  },
  [canRepeatTask](_skip, { checkPermission }) {
    return checkPermission(canRepeatTask);
  },
  [canUseCard](_skip, { checkPermission }) {
    return checkPermission(canUseCard);
  },
};

const mutations = {
  setPermission(state, permission) {
    state.permissions = [...state.permissions, permission];
    localStorage.setItem('billingPermissions', JSON.stringify(state.permissions));
  },

  deletePermission(state, permission) {
    state.permissions = state.permissions.filter((_permission) => _permission !== permission);
    localStorage.setItem('billingPermissions', JSON.stringify(state.permissions));
  },

  reset(state) {
    Object.assign(state, createInitialState());
  },
};

const actions = {
  INITIALIZE_BILLING_PERMISSIONS({ commit, getters }, tariff) {
    try {
      const _canUseFeed = tariff.plan ? tariff.plan.is_can_use_feed : tariff.is_can_use_feed;
      const _canManualAddTime = tariff.plan ? tariff.plan.is_can_manual_add_time : tariff.is_can_manual_add_time;
      const _canUseCustomFields = tariff.plan ? tariff.plan.is_can_use_custom_fields : tariff.is_can_use_custom_fields;
      const _canUseTaskHistory = tariff.plan ? tariff.plan.task_changes_management : tariff.task_changes_management;
      const _canUseTaskCovers = tariff.plan ? tariff.plan.is_can_use_task_covers : tariff.is_can_use_task_covers;
      const _canUseCardType = tariff.plan ? tariff.plan.is_can_use_task_type : tariff.is_can_use_task_type;
      const _canUseFinances = tariff.plan ? tariff.plan.is_can_use_finances : tariff.is_can_use_finances;
      const _canUseGantt = tariff.plan ? tariff.plan.is_can_gantt_view : tariff.is_can_gantt_view;
      const _canChangeSharingSettings = tariff.plan
        ? tariff.plan.is_can_change_sharing_settings
        : tariff.is_can_change_sharing_settings;
      const _canManageTemplates = tariff.plan
        ? tariff.plan.task_template_access_management
        : tariff.task_template_access_management;
      const _canRepeatTask = tariff.plan
        ? tariff.plan.task_template_schedule_access_management
        : tariff.task_template_schedule_access_management;
      const _canUseCard = tariff.plan ? tariff.plan.is_can_create_task_via_email : tariff.is_can_create_task_via_email;

      const billingPermissions = new Map([
        [canUseTaskCovers, _canUseTaskCovers],
        [canUseFeed, _canUseFeed],
        [canManualAddTime, _canManualAddTime],
        [canUseCustomFields, _canUseCustomFields],
        [canUseTaskHistory, _canUseTaskHistory],
        [canUseCardType, _canUseCardType],
        [canChangeSharingSettings, _canChangeSharingSettings],
        [canUseFinances, _canUseFinances],
        [canUseGantt, _canUseGantt],
        [canManageTemplates, _canManageTemplates],
        [canRepeatTask, _canRepeatTask],
        [canUseCard, _canUseCard],
      ]);
      billingPermissions.forEach((value, key) => {
        if (value && !getters[key]) {
          commit('setPermission', key);
        } else if (!value && getters[key]) {
          commit('deletePermission', key);
        }
      });
    } catch (e) {
      console.error('Ошибка при инициализации прав биллинга', e);
    }
  },
};

export default {
  namespaced: true,

  state,
  getters,
  mutations,
  actions,
};
