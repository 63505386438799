export default {
  settings: {
    block: 'Block',
    width: 'Full width',
    convertToHTML: 'Export HTML',
    convertToPDF: 'Export PDF',
  },
  cover: 'Cover',
  tabs: {
    upload: 'Upload',
    link: 'Link',
    unsplash: 'Unsplash',
  },
  reset: 'Reset',
  upload: {
    file: 'Upload file',
    label: 'Images wider than 1500px work best.',
    warn: 'Maximum file size is 5MB.',
  },
  link: {
    placeholder: 'Insert image link',
    upload: 'Upload',
    label: 'Works with any image from the internet',
  },
  error: {
    link: 'Unable to retrieve image, check link',
    image: 'There is no image at this link',
  },
};
