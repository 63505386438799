import session from '@/api/session';
import { mailDetails } from '@/models/teams/TeamIntegration';

import ZodHelper from '@/helpers/ZodHelper';

type GetIntegration = {
  teamId: number;
  integrationId: string;
};

type RequestConnect = {
  host: string;
  host_username: string;
  host_password: string;
  host_port: number;
  protocol: string;
  use_ssl: boolean;
};

type RequestCreate = RequestConnect & {
  integration_name: string;
  datasource: number;
  task_type: number;
  status: number | null;
};

type CreatIntegration = {
  teamId: number;
  requestBody: RequestCreate;
};

type ConnectIntegration = {
  teamId: number;
  requestBody: RequestConnect;
};

type UpdateIntegration = GetIntegration & {
  requestBody: RequestCreate;
};

type VerificationIntegration = {
  teamId: number;
  requestBody: Pick<RequestConnect, 'host_username'>;
};

export default {
  getMailIntegrationDetails({ teamId, integrationId }: GetIntegration) {
    return ZodHelper.request(
      () => session.get(`datasource/email-integration/${teamId}/detail/${integrationId}/`),
      mailDetails,
    );
  },
  createMailIntegration({ teamId, requestBody }: CreatIntegration) {
    return session.post(`datasource/email-integration/${teamId}/create/`, requestBody);
  },
  connectMailIntegration({ teamId, requestBody }: ConnectIntegration) {
    return session.post(`datasource/email-integration/${teamId}/check-connection/`, requestBody);
  },
  updateMailIntegration({ teamId, integrationId, requestBody }: UpdateIntegration) {
    return session.put(`datasource/email-integration/${teamId}/update/${integrationId}/`, requestBody);
  },
  verificateMailIntegration({ teamId, requestBody }: VerificationIntegration) {
    return session.post(`datasource/email-integration/${teamId}/send-email/`, requestBody);
  },
};
