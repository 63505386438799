import Translation from '@/utils/translation';

export enum DurationCodes {
  MINUTE = 'minute',
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

export const durationIntervals = [
  {
    code: DurationCodes.MINUTE,
    title: new Translation('tasks.details.properties.interval.minute'),
    shortTitle: new Translation('tasks.details.properties.short.minute'),
  },
  {
    code: DurationCodes.HOUR,
    title: new Translation('tasks.details.properties.interval.hour'),
    shortTitle: new Translation('tasks.details.properties.short.hour'),
  },
  {
    code: DurationCodes.DAY,
    title: new Translation('tasks.details.properties.interval.day'),
    shortTitle: new Translation('tasks.details.properties.short.day'),
  },
  {
    code: DurationCodes.WEEK,
    title: new Translation('tasks.details.properties.interval.week'),
    shortTitle: new Translation('tasks.details.properties.short.week'),
  },
  {
    code: DurationCodes.MONTH,
    title: new Translation('tasks.details.properties.interval.month'),
    shortTitle: new Translation('tasks.details.properties.short.month'),
  },
];

const durationMultipliers = {
  [DurationCodes.MINUTE]: 1,
  [DurationCodes.HOUR]: 60,
  [DurationCodes.DAY]: 60 * 24,
  [DurationCodes.WEEK]: 60 * 24 * 7,
  [DurationCodes.MONTH]: 60 * 24 * 30,
};

export const convertDuration = (value: number, fromCode: DurationCodes, toCode: DurationCodes) => {
  const minutes = (value * durationMultipliers[fromCode]) / durationMultipliers[toCode];
  return String(Math.round(minutes * 100) / 100);
};
