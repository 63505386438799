export default {
  infoIntegration: {
    forWhatTitle: 'Why do you need Shtab integration with Mail',
    linkDoc: 'Link to documentation',
    readDoc: 'Instructions for connecting the integration',
    mailSentMessage: 'A test message has been sent to your email address.',
    verification:
      'Great! Now we need to make sure everything is set up correctly. Create a test card for verification.',
    forWhatDescription:
      'When integrated with Shtab, all emails that arrive in the mail automatically become tasks on the kanban board. This feature is suitable for those who need to process requests. The email can be any email: Gmail, Mail.ru, Yandex.Mail, etc.',
  },
  steps: {
    start: 'Start working',
    authorization: 'Authorization',
    connection: 'Connection',
    button: {
      delete: 'Delete',
      authorization: 'Connection check',
    },
    error: {
      authorization:
        'Error when checking connection. Check the entered data, if the problem persists, please write us an e-mail.',
    },
  },
  create: {
    title: 'Title',
    datasource: 'Database',
    status: 'Status',
    cardType: 'Card type',
    info: {
      titleMessage: 'Title of the integration',
      datasourceMessage: 'Select the database to which the integration will be attached',
      statusMessage: 'Select the status to which tasks will be created',
      cardTypeMessage: 'Select the card type for tasks',
    },
    input: {
      title: 'Enter your title',
      datasource: 'Select a database',
      status: 'Select a status',
      cardType: 'Select a card type',
    },
  },
  initialization: {
    email: 'E-mail',
    protocol: 'Protocol',
    password: 'Password',
    host: 'Host',
    port: 'Port',
    ssl: 'use ssl',
    input: {
      email: 'Enter your e-mail',
      protocol: 'Select a value',
      password: 'Enter your password',
      host: 'Enter your host',
      port: 'Enter your port',
      select: 'Select a value',
    },
    info: {
      emailMessage: 'Enter the email address from which tasks will be created.',
      protocolMessage: 'Select the protocol for your mail (IMAP or POP3).',
      passwordMessage: 'Enter the password to access your mail through the integration.',
      hostMessage: 'Enter the address of your mail server.',
      portMessage: 'Enter the port number for the connection.',
    },
  },
};
