import { z } from 'zod';

import { taskTypeDetail } from '@/models/CardTypes';
import { templatesExecutor } from '@/models/tasks/TaskTemplate';

export const subTemplates = z
  .object({
    id: z.number(),
    name: z.string(),
    files_count: z.number(),
    checklist_count: z.number(),
    comments_count: z.number(),
    subtemplates_count: z.number(),
    template_executor: templatesExecutor.array(),
    type: taskTypeDetail.omit({ fields: true }).nullable(),
  })
  .transform((value) => ({ ...value, executors: value.template_executor }));

export type SubTemplates = z.infer<typeof subTemplates>;
