export default {
  projects: 'Projects',
  cards: 'Cards',
  myTasks: 'My cards',
  seoMyTasks: 'My tasks - {name}',
  screenshots: 'Screenshots',
  seoScreenshots: 'Screenshots - ',
  history: 'Changes history',
  seoHistory: 'Changes history - ',
};
