import { z } from 'zod';

import { DefaultFields } from '@/models/tasks/TaskDefaultField';

import { customFieldCodes } from '@/constants/tasks/customFields';

export enum ViewTaskFields {
  NAME = 'name',
  SLUG = 'slug',
  DATASOURCE = 'datasource',
  EXECUTORS = 'executors',
  RESPONSIBLE = 'responsibles',
  DATE = 'date',
  STATUS = 'status',
  PRIORITY = 'priority',
  DURATION = 'estimate',
  STAGE = 'stage',
  LINKS = 'connections',
  TAGS = 'labels',
  COST = 'cost',
  TRACKER = 'tracker',
  CUSTOM_FIELD = 'custom_field',

  REPEAT = 'repeat',
  CARD_TYPE = 'card_type',
  VIEWERS = 'viewers',
  COVER = 'cover',
  INTERACTIVE_BUTTONS = 'interactive_buttons',
  INFORMATIONAL_BUTTONS = 'informational_buttons',
}

export const taskViewFieldMapping = {
  [ViewTaskFields.EXECUTORS]: DefaultFields.EXECUTORS,
  [ViewTaskFields.DATE]: DefaultFields.DUE_DATE,
  [ViewTaskFields.STATUS]: DefaultFields.STATUS,
  [ViewTaskFields.PRIORITY]: DefaultFields.PRIORITY,
  [ViewTaskFields.DURATION]: DefaultFields.DURATION,
  [ViewTaskFields.STAGE]: DefaultFields.STAGE,
  [ViewTaskFields.LINKS]: DefaultFields.LINKS,
  [ViewTaskFields.TAGS]: DefaultFields.TAGS,
  [ViewTaskFields.COST]: DefaultFields.COST,
} as Record<ViewTaskFields, DefaultFields>;

export const viewCustomField = z.object({
  id: z.string(),
  name: z.string(),
  type: z.nativeEnum(customFieldCodes),
});

export const taskElements = z.object({
  id: z.number(),
  show: z.boolean(),
  type: z.nativeEnum(ViewTaskFields),
  custom_field: viewCustomField.nullable(),
});

export type TaskElement = z.infer<typeof taskElements>;
export type ViewCustomField = z.infer<typeof viewCustomField>;
