import { z } from 'zod';

import session from '@/api/session';
import { datasourceInList, datasource } from '@/models/Datasource';

import ZodHelper from '@/helpers/ZodHelper';

export type GetTeamDataSources = {
  teamId: number;
  getParams: {
    page: number;
    search?: string;
    status?: number;
    stage?: number;
    task_label?: number;
    executor?: number;
    available_for_integration?: string;
  };
};
type GetTeamDatasourceDetails = { teamId: number; datasourceId: number };
type CreateTeamDatasource = {
  teamId: number;
  requestBody: { name: string; slug?: string; comment?: string; team: number };
  getParams?: { create_for_view: boolean };
};
type EditTeamDatasource = GetTeamDatasourceDetails & CreateTeamDatasource;
type DeleteTeamDatasource = GetTeamDatasourceDetails;
export default {
  getTeamDataSources({ teamId, getParams }: GetTeamDataSources) {
    return ZodHelper.request(
      () => session.get(`/datasource/${teamId}/`, { params: getParams }),
      ZodHelper.getPaginationModel(datasourceInList.array()),
    );
  },
  getTeamDatasourceDetails({ teamId, datasourceId }: GetTeamDatasourceDetails) {
    return ZodHelper.request(() => session.get(`/datasource/${teamId}/${datasourceId}/`), datasource);
  },

  createTeamDatasource({ teamId, requestBody, getParams }: CreateTeamDatasource) {
    return ZodHelper.request(
      () => session.post(`/datasource/${teamId}/`, requestBody, { params: getParams }),
      datasource,
    );
  },

  editTeamDatasource({ teamId, datasourceId, requestBody }: EditTeamDatasource) {
    return ZodHelper.request(() => session.patch(`/datasource/${teamId}/${datasourceId}/`, requestBody), datasource);
  },

  deleteTeamDatasource({ teamId, datasourceId }: DeleteTeamDatasource) {
    return ZodHelper.request(
      () => session.delete(`/datasource/${teamId}/${datasourceId}/`),
      z.object({ deleted_views: z.array(z.number()) }),
    );
  },
};
