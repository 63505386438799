import session from '@/api/session';
import { user } from '@/models/User';

import ZodHelper from '@/helpers/ZodHelper';

type GetUserParams = {
  datasourceId: number;
  templateId: number;
  getParams: { search?: string; page?: number };
};

export const apiTaskTemplateUsers = {
  getTemplateUsers({ datasourceId, templateId, getParams }: GetUserParams) {
    return ZodHelper.request(
      () => session.get(`tasks/task-templates/${datasourceId}/users/${templateId}/`, { params: getParams }),
      ZodHelper.getPaginationModel(user.array()),
    );
  },
};
