import session from '@/api/session';
import getRequestUrl from '@/api/utils/getRequestUrl';

export default {
  getTaskComments(taskId) {
    const defaultUrl = `/tasks/comments/${taskId}/list/`;
    const sharedUrl = `shared/tasks/comment/${taskId}/`;

    return session.get(getRequestUrl(defaultUrl, sharedUrl));
  },

  getTaskCommentsTemplates({ datasourceId, templateId }) {
    return session.get(`/tasks/task-templates/${datasourceId}/comments/${templateId}/`);
  },

  createTaskComment({ taskId, requestBody }) {
    const defaultUrl = `/tasks/comments/${taskId}/create/`;
    const sharedUrl = `shared/tasks/comment/${taskId}/`;
    return session.post(getRequestUrl(defaultUrl, sharedUrl), requestBody);
  },

  createTemplatesComment({ datasourceId, templateId, requestBody }) {
    return session.post(`/tasks/task-templates/${datasourceId}/comments/${templateId}/create/`, requestBody);
  },

  deleteTaskComment({ taskId, commentId, requestBody }) {
    return session.delete(`/tasks/comments/${taskId}/delete/${commentId}/`, { data: requestBody });
  },

  deleteTemplatesComment({ datasourceId, templateId, commentId, requestBody }) {
    return session.delete(`/tasks/task-templates/${datasourceId}/comments/${templateId}/${commentId}/`, {
      data: requestBody,
    });
  },

  editTaskComments({ taskId, commentId, requestBody }) {
    return session.put(`/tasks/comments/${taskId}/edit/${commentId}/`, requestBody);
  },

  editTemplatesComments({ datasourceId, templateId, commentId, requestBody }) {
    return session.patch(`/tasks/task-templates/${datasourceId}/comments/${templateId}/${commentId}/`, requestBody);
  },
};
