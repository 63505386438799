import { z } from 'zod';

import { taskTypeMinimum } from '@/models/CardTypes';
import { datasource } from '@/models/Datasource';
import { user } from '@/models/User';
import { datasourceStage } from '@/models/datasource/DatasourceStage';
import { teamStatus } from '@/models/teams/TeamStatus';
import { teamTag } from '@/models/teams/TeamTag';
import { viewBase } from '@/models/views/View';

import TextHelper from '@/helpers/TextHelper';

import { ViewScreens } from '@/constants/views/viewScreens';

const filterData = z.object({
  type: z.nativeEnum(ViewScreens),
  filters: z.record(z.string()),
  viewId: z.number().optional(),
});
export const tasksUserFilter = z
  .object({
    id: z.number(),
    name: z.string(),
    task_filter: z.string(),
    icon: z.string(),
    emoji: z.string(),
    avatar: z.string().nullable(),
  })
  .transform(({ task_filter, ...value }) => ({
    ...value,
    data: filterData.parse(TextHelper.safeParseJSON(task_filter)),
  }));

export const reportFilter = tasksUserFilter
  .innerType()
  .omit({ data: true, task_filter: true })
  .extend({
    url: z.object({
      name: z.string(),
      query: z.record(z.string()),
    }),
  });

export const userFilter = reportFilter.extend({
  url: z.object({
    name: z.string(),
    query: z.record(z.string()),
    params: z.object({
      teamId: z.string(),
      viewId: z.string(),
    }),
  }),
});

export const tasksUserFilterDetails = z
  .object({
    data: z.object({
      creators: user.array().optional(),
      responsibles: user.array().optional(),
      executors: user.array().optional(),
      statuses: teamStatus.innerType().omit({ tasks_count: true }).array().optional(),
      types: taskTypeMinimum.array().optional(),
      tags: teamTag.array().optional(),
      stages: datasourceStage.array().optional(),
      datasources: datasource.array().optional(),
      time_by_users: user.array().optional(),
      views: viewBase.array().optional(),
    }),
  })
  .transform(({ data }) => data);

export type UserFilter = z.infer<typeof userFilter>;
export type ReportFilter = z.infer<typeof reportFilter>;
