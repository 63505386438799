import { z } from 'zod';

export const taskChecklist = z.object({
  id: z.number(),
  text: z.string(),
  resolved: z.boolean(),
  position: z.number(),
});

export type TaskChecklist = z.infer<typeof taskChecklist>;
